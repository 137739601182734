const zh_CN = {
    Add: "添加",
    Update: "修改",
    Delete: '删除',
    Move: "移动",
    Yes: '是',
    No: '否',
    OK: "确定",
    Cancel: '取消',
    Import: "批量导入",
    Reimport: "重新导入",
    Export: "导出",
    Search: "搜索",
    All: "全部",
    Save: "保存",
    See: '查看',
    Reset: "重置",
    MoreOperations: '更多操作',
    Prompt: '提示',
    Issue: '下发',
    IssuingDetails: '下发详情',
    DownTime: '下发时间',
    ApplicationState: "应用状态",
    DeviceDetails: '设备详情',
    UserDetails: '用户详情',
    Size: '大小',
    Copy: "复制",
    UserInfo: "用户信息",
    ParentManagement: "家长管理",
    DeviceManagement: "孩子管理",
    Device: "设备",
    DeviceLimitation: "设备限制",
    ApplicationManagement: '应用管理',
    SecurityPolicy: "安全策略",
    FileManagement: '文件管理',
    AccountInfo: "账号资料",
    Modifypassword: "修改密码",
    Logout: "退出登录",
    FullName: "姓名",
    ChineseName: "中文名称",
    EnglishName: "英文名称",
    RequestUrl: "请求地址",
    RequestMethod: "请求方法",
    Sort: "排序",
    IsItMenu: "是否是菜单",
    Settings: '设置',
    MenuManagement: "菜单管理",
    RoleManagement: "角色管理",
    AccountManagement: "账号管理",
    UserLabel: "用户标签",
    DeviceAccess: "设备准入",
    HomePage: "首页",
    ParentMenu: "父级菜单",
    Submenu: "子级菜单",
    Button: "按钮",
    IconName: "图标名称",
    Icon: "图标",
    toplevel: "顶级",
    Name: '名称',
    Description: '描述',
    CreationTime: "创建时间",
    AssignPermissions: "分配权限",
    Account: '账号',
    Mailbox: "邮箱",
    Role: "角色",
    MobileNumber: "手机号码",
    Password: '密码',
    ConfirmPassword: "确认密码",
    PasswordGenerationMode: "密码生成方式",
    AutoGenerate: "自动生成",
    ManualInput: '手动输入',
    ActivationDevice: "激活孩子",
    ActivationState: '激活状态',
    AlreadyActivated: '已激活',
    NotActive: '未激活',
    AddMode: "添加方式",
    PhoneCornet: "手机短号",
    DownloadApp: "下载应用",
    DownloadTemplate: "下载导入模板",
    DownloadUserTemplate: "下载用户模板",
    DownloadDeviceTemplate: "下载设备模板",
    SendMailActivation: "发送邮件激活",
    Configure: "配置",
    LimitSIMCard: "限制SIM卡IMSI",
    LimitDevice: "限制终端IMEI",
    LimitSystem: "限制系统",
    LimitTFCard: "限制TF卡序列号",
    LimitName: "限制姓名",
    LimitIDCard: "限制身份证",
    DeviceAccessInformation: "设备准入信息",
    Creator: "创建者",
    AlreadyApplied: '已应用',
    NotApply: '未应用',
    HaveReceived: "已收到",
    AlreadyIssued: '已下发',
    NotIssued: "未下发",
    AlreadyInstalled: '已安装',
    NotInstalled: '未安装',
    UpdateTime: '更新时间',
    BasicInformation: '基本信息',
    StrongControl: "强管控",
    DeviceLockScreen: "设备锁屏",
    ViolationDetection: "违规检测",
    PrivacyPolicy: "隐私策略",
    Camera: '摄像头',
    Entrance: "入口",
    Exit: "出口",
    SelectFence: "选择围栏",
    SelectDate: "选择日期",
    TimeFence: '时间围栏',
    Geofence: '地理围栏',
    WLANFence: "WLAN围栏",
    OnlineState: "在线状态 ",
    Online: '在线',
    Offline: '离线',
    DeviceModel: '设备型号',
    ClientVersion: '客户端版本',
    State: '状态',
    TableOperation: "表格操作",
    Operation: '操作',
    ProhibitionUse: "禁止使用",
    AllowUse: "允许使用",
    ScanAllowedOnly: "仅允许扫描",
    DeviceUpdate: '设备更新',
    PlayRingtone: '播放铃声',
    SendMessage: '发送消息',
    LockDevice: '设备锁定',
    UnlockDevice: '设备解锁',
    Open: '开启',
    Close: '关闭',
    EnableCallWhiteList: "开启电话白名单",
    DisableCallWhiteList: '关闭电话白名单',
    UpdateTelephoneBook: "更新电话本",
    EraseDevice: '设备擦除',
    ReleaseControl: '解除管控', 
    ObsoleteDevice: '设备淘汰',
    SetFlow: '设置流量',
    DeviceShutdown: '设备关机',
    DeviceReset: '设备重启',
    SwitchToSecurityDomain: '切换至安全域',
    SwitchToLifeDomain: '切换至生活域',
    MessageType: "消息类型",
    Notice: "通知",
    Mail: "邮件",
    MessageContents: "消息内容",
    Version: '版本',
    Platform: '平台',
    AllPlatforms: "所有平台",
    ApplicationName: '应用名称',
    ApplicationSize: '应用大小',
    Operator: '操作者',
    Operator2: '运营商',
    UploadAppPackage: "上传应用安装包",
    LowestRequirement: '最低操作系统要求',
    DescriptionClauses: '描述及使用条款',
    AllowAccess: '允许上网',
    AllowUnloading: '允许卸载',
    DeviceStatus: '设备状态',
    Application: '应用',
    Files: '文件',
    Strategy: '策略',
    DeviceType: '设备类型',
    DeviceName: '孩子姓名',
    CurrentOS: '当前操作系统',
    AppPassword: 'app屏保密码',
    ActivationTime: '激活时间',
    LatestOnline: '最新上线',
    LastUpdateTime: '上次更新时间',
    DeviceManufacturer: '设备厂商',
    SDCardCapacity: 'SD卡容量',
    MainCard: '主卡',
    SecondaryCard: '副卡',
    Android: '安卓',
    AlreadyLocked: '已锁定',
    LostConnection: '已失联',
    Irregularities: '已违规',
    Eliminated: "已淘汰",
    Erased: "已擦除",
    SDCardNumber: 'SD卡序列号',
    SDCardCapacityCanUse: 'SD卡可用容量',
    // Camera: '相机',
    MACAddress: 'MAC地址',
    Model: '机型',
    ModelName: "机型名称",
    TotalCapacityOfROM: 'ROM总容量',
    ROMAvailableCapacity: 'ROM可用容量',
    PowerState: '电源状态',
    StartingTime: '开机时长',
    BluetoothMACAddress: '蓝牙MAC地址',
    DeviceMACAddress: "设备MAC地址",
    WhetherOrNotRooted: '是否Rooted',
    PrisonBreak: '越狱',
    OutContact: '失联',
    HardwareInformation: '硬件信息',
    TrafficInformation: '流量信息',
    FlowQuota: '流量配额',
    MainCardTraffic: '主卡移动网络流量',
    ViceTraffic: '副卡移动网络流量',
    Today: '当天',
    ThisWeek: '本周',
    ThisMonth: '本月',
    AppPackageName: '应用程序包名',
    Type: '类型',
    ViolatingPolicy: '违反策略',
    InsideTheFence: "围栏内",
    OutsideTheFence: "围栏外 ",
    MobileData: "移动数据",
    NoControl: "不管控",
    ForcedOpening: "强制开启",
    ForcedClosure: "强制关闭",
    RestrictedUse: "受限使用",
    Bluetooth: "蓝牙",
    BluetoothConfig: "蓝牙配置",
    TimeRange: "时间范围",
    GeographicRange: "地理范围",
    longitude: '经度',
    latitude: '纬度',
    Range: "范围",
    Kilometer: "公里",
    Mile: "英里",
    MapSelectionScope: "地图选择范围",
    SecurityConfigItem: "安全配置项",
    InfraredControl: "红外控制",
    Biometrics: "生物识别",
    LocationServices: "定位服务",
    LocateDevice: "设备定位",
    USBWorkMode: "USB工作模式",
    ChargeOnly: "仅充电",
    AccessingExtendedStorage: "扩展存储访问",
    AllowReadAndWrite: "允许读和写",
    ProhibitionOfReadAndWrite: "禁止读和写",
    OnlyAllowRead: "仅允许读",
    Microphone: "麦克风",
    Speaker: "扬声器",
    FlashLight: "闪光灯",
    ExtendedPeripherals: "扩展外设",
    Call: "通话",
    SMS: "短信",
    Screenshot: "截屏",
    NetworkSharing: "网络共享",
    APNManagement: "APN管理",
    APN: 'APN',
    TimeSetting: "时间设置",
    RestoreFactorySettings: "恢复出厂设置",
    DebuggingMode: "开发调试模式",
    SystemUpgrade: "系统升级",
    OnlyWhiteListCalls: "仅允许白名单通话",
    OnlyWhiteListSMS: "仅允许白名单短信",
    APNNotAllow: "不允许增加、删除、修改、查看以及选择APN",
    APNAllow: "允许增加、删除、修改、查看以及选择APN",
    AllowOnlyView: "仅允许查看",
    ForcedUseNetworkTime: "强制使用网络时间",
    IssueApplication: '下发应用',
    UninstallApplication: "卸载应用",
    IssueFile: '下发文件',
    DeleteFiles: "删除文件",
    IssuePolicy: "下发策略",
    IssueRestrictionPolicy: "下发限制策略",
    IssueSensitivePolicy: "下发敏感词策略",
    IssueAppInstallPolicy: "下发应用安装策略",
    AppInstallPolicy: "下发应用安装策略",
    IssueAppRunPolicy: "下发应用运行策略",
    AppRunPolicy: '下发应用运行策略',
    IssueSecureDesktopPolicy: "下发安全桌面策略",
    IssueSecureBrowserPolicy: "下发安全浏览器策略",
    IssueSystemViolation: "下发系统违规",
    IssueApplicationViolation: "下发应用违规",
    IssueLossViolation: "下发失联违规",
    IssueFencePolicy: "下发围栏策略",
    FencePolicy: "围栏策略",
    IssueTrackPolicy: "下发轨迹策略",
    IssueSMSPolicy: "下发短信策略",
    IssueCallRecordingPolicy: "下发通话录音策略",
    IssueAPN: "下发APN配置",
    IssueVPN: "下发VPN配置",
    VPN: 'VPN',
    Wifi: 'Wifi',
    IssueWifi: "下发WIFI配置",
    FileSize: "文件大小",
    ConfigurationName: "配置名称",
    AutoJoin: "自动加入",
    HiddenNetwork: "隐藏网络",
    ServiceSetIdentifier: "服务集标识符",
    SecurityType: "安全类型",
    Null: "无",
    WEPPersonalLevel: "WEP个人级",
    WPALevel: "WPA/WPA2个人级",
    ConnectionType: "连接类型",
    SharedSecretKey: "共享秘钥",
    UserName: "用户名",
    Encrypt: "加密",
    AgentServer: "代理服务器",
    Port: "端口",
    ParentRole: "父级角色",
    RingFencePolicy: "围栏策略",
    Fencing: "围栏方式",
    TimeUnit: "时间单元",
    StartTime: "开始时间",
    EndTime: "结束时间",
    EveryDay: "每天",
    weekly: "每周",
    WorkingDay: "工作日",
    CertainRimes: "特定时间",
    Monday: "星期一",
    Tuesday: "星期二",
    Wednesday: "星期三",
    Thursday: "星期四",
    Friday: "星期五",
    Saturday: "星期六",
    Sunday: "星期日",
    HardwareControl: "硬件管控",
    AssociatedUser: "关联用户",
    LabelName: "标签名称",
    SelectUsers: "选择用户",
    PolicyType: "策略类型",
    DevicePolicy: "设备策略",
    RestrictionPolicy: "限制策略",
    SensitiveWordPolicy: "敏感词策略",
    SensitivePolicy: "敏感词策略",
    TrackPolicy: "轨迹策略",
    CallRecordingPolicy: "通话录音策略",
    SMSPolicy: "短信策略",
    ViolationPolicy: "违规策略",
    SystemViolation: "系统违规",
    ApplicationViolation: "应用违规",
    LossViolation: "失联违规",
    AppPolicy: "应用策略",
    ApplyControlPolicy: "应用管控策略",
    SecureDesktop: "安全桌面",
    SecureDesktopPolicy: "安全桌面策略",
    SecureBrowser: "安全浏览器",
    SecureBrowserPolicy: "安全浏览器策略",
    BlackAndWhiteList: "黑白名单",
    AppFence: "应用围栏",
    FaceZones: "人脸区域管理",
    AccessControlZone: "门禁区域管理",
    UserStatus: "用户状态",
    RegionalState: "区域状态",
    FaceZonePolicy: "人脸区域策略",
    AccessControlPolicy: "门禁区域策略",
    ZoneConfiguration: '区域配置',
    CameraConfiguration: '摄像头配置',
    MonitoringLog: '监控日志',
    EnterTheRegion: "进入区域",
    ControlState: "管控状态",
    Enter: '进入',
    OutOf: "退出",
    NotEntered: '未进入',
    Control: '已管控',
    NotControlled: '未管控',
    ControlFailure: '管控失败',
    FacePhoto: '人脸照片',
    AddToLabel: "添加至标签",
    UserAssociationList: "用户关联列表",
    RTSPStreaming: "RTSP串流",
    CameraID: "摄像头ID",
    CameraName: "摄像头名称",
    CameraType: "摄像头类型",
    ZoneName: "区域名称",
    CurrentPersonnel: '当前人员',
    EnterAbnormity: '进入异常',
    LeaveAbnormity: '离开异常',
    ProcessingEntryAbnormality: '处理进入人员异常',
    ProcessingOutOfAbnormality: '处理离开人员异常',
    Logs: '日志',
    SwitchoverStatus: '切换状态',
    Time: '时间',
    Action: '动作',
    SDCardChange: 'SD卡变更',
    SIMCardChange: 'SIM卡变更',
    ViolationTreatment: "违规处理",
    LockScreenPassword: "锁屏密码",
    ViolationAlarm: "用户违规告警",
    MailNotification: "邮件通知",
    PushMessage: "推送消息",
    AdminAlarm: "管理员告警",
    BlackList: "黑名单",
    WhiteList: "白名单",
    lossTime: "失联时间",
    DelayExecution: "延时执行",
    AlarmSettings: '告警设置',
    ReceivingAlarmMailbox: "接收告警邮箱地址",
    AccessKey: "门禁KEY",
    ManagementPlatform: '平台管理',
    BasicSettings: '基本设置',
    InterfaceTimeout: "界面超时时间",
    AnyHours: "{n}小时",
    AnyMinutes: "{n}分钟",
    LogSavingDays: "日志保存天数",
    AuthorizationPermission: '授权许可',
    UploadLicense: "上传License文件",
    LicenseInfo: "License授权信息",
    CustomerName: "客户名称",
    Template: "模块",
    LicenseType: "License类型",
    OfficialUse: "正式使用",
    OnTrial: "试用",
    AvailableDate: "可使用日期",
    UpdateDate: "升级日期",
    NumberLicenses: "License数量",
    NumberUsedLicenses: "已使用License数量",
    PersonalizedSettings: '个性化设置',
    ChangeLogo: "LOGO和背景图修改",
    BackgroundImage: "背景图",
    ActivateMailTemplate: "激活邮件模板设置",
    MailTheme: "邮件主题",
    MailContent: "邮件内容",
    ClientManagement: '客户端管理',
    VersionUpdate: '版本更新',
    AboutPlatform: '关于平台',
    HelpInformation: "帮助信息",
    SupportingContents: "支持内容",
    LicenseAgreement: "协议许可",
    UploadNewVersion: "上传新版本{name}",
    operatingSystem: "操作系统",
    LatestVersion: "最新版本",
    Upload: "上传",
    ReleasedVersion: "发布版本",
    CustomerCerviceNumber: "客服电话",
    CopyrightStatement: "版权声明",
    SystemManagement: "操作系统管理",
    ModelManagement: "机型管理",
    PackageName: "包名称",
    SystemType: "操作系统类型",
    SingleSystem: "单系统",
    DualSystem: "双系统",
    SystemVersion: "操作系统版本",
    FitModel: "适配机型",
    OperationRecord: "操作记录",
    SpecificOperation: "具体操作",
    InterInorUn: '应用交互安装/卸载',
    SilInorUn: '应用静默安装/卸载',
    ADBInorUn: '应用adb方式安装/卸载',
    AuthorizedUse: '授权使用',
    OpenUse: '开放使用',
    AppAuthorWhitelist: "应用授权白名单",
    ApplicationOperationControl: '应用运行控制',
    Forbi: '禁止运行',
    AlwaysAllowRun: "始终允许运行",
    AlwaysProhibit: "始终禁止运行",
    ForcedOperation: '强制运行',
    AppWhitelist: "应用白名单",
    AppBlacklist: "应用黑名单",
    DualDomainSwitch: '双域切换',
    ApplicationConstraints: "应用受限方式",
    OnlyRunInFence: "仅允许在围栏内运行",
    ReturnToLogin: "返回登录",
    TotalRecords: "共 {totalNum} 条记录，已选择 {selectedNum} 条记录",
    UserImportCount: "已导入 {num1} 个用户，还可导入 {num2} 个用户",
    ParentImportCount: "已导入 {num1} 个家长，还可导入 {num2} 个家长",
    StudentImportCount: "已导入 {num1} 个孩子，还可导入 {num2} 个孩子",
    UserImportExcessive: "导入用户数量超额，当前只能导入 {num} 个用户，请升级license证书！",
    DeviceImportCount: "已导入 {num} 个设备",
    GetDeviceDetails: "获取设备详情",
    Reissue: "重新下发",
    DeleteByDevice: "撤回",
    IssueByUser: "按用户下发",
    IssueByDevice: "按设备下发",
    DayAccount: "{days}天{hours}小时{minutes}分钟",
    HoursAccount: "{hours}小时{minutes}分钟",
    MinutesAccount: "{minutes}分钟",
    Unexecuted: "未执行",
    Timing: "计时中",
    Executed: "已执行",
    Relieved: "已解除",
    IDCard: "身份证",
    Identity: "身份",
    Father: "父亲",
    Mather: "母亲",
    Gender: "性别",
    Man: "男",
    Woman: "女",
    RegistrationEquipment: "注册设备",
    ActivatingQRCode: "激活二维码",
    DeleteApp: "删除应用",
    HomeAddress: "家庭住址",
    Job: "职务",
    JobManagement: "职务管理", 
    Occupation: "职业", 
    SureDelete: "删除后数据将不可恢复，您确定要执行此操作？",
    InputChineseName: "请输入菜单中文名称",
    InputEnglishName: "请输入菜单英文名称",
    PleaseCheck: "请选中表格中的某一条记录",
    SelectRoles: "请选择角色",
    SelectUnit: "请选择工作单位",
    PleaseConfirmPassword: "请确认密码",
    TwoPasswordDiff: "两次输入的密码不一致",
    passwordRule: '请设置首字母大写，必须字母+数字，至少8位数',
    password6Bit: '密码必须是6位字母或数字',
    InputCorrect: "请输入正确的{name}",
    ExistsName: "已存在相同名称",
    ExistsPolicyName: "已存在相同策略名称",
    ExistsAccount: "已存在相同账号",
    ExistsCardNo: "已存在相同身份证号码",
    ExistsIMEI: "已存在相同IMEI",
    ExistsPhone: "该手机号码已注册",
    CannotSuperRole: "不能选择超级管理员角色",
    CannotDeleteRole: "不能删除最高级角色",
    UploadTempFormat: "请使用模板格式上传",
    InputNumChars: "请输入{n}位字符",
    LineFormatError: "第 {n} 行格式错误",
    UploadFormat: "请按照模板格式上传",
    lineEmpty: "第 {n} 行 {name} 不能为空",
    lineIncorrect: "第 {n} 行 {name} 格式不正确",
    lineRepeat: "第 {n} 行和第 {m} 行 {name} 重复了",
    ImportUser: "请导入用户信息",
    AccountExists: "账号 {name} 已存在",
    AccountNotExist: "账号 {name} 不存在",
    PhoneExists: "手机号码 {name} 已存在",
    CardNoExists: "身份证号码 {name} 已存在",
    DeviceNameExists: "设备名称 {name} 已存在",
    DeviceNameNotExists: "设备名称 {name} 不存在",
    IMEIExists: "IMEI {name} 已存在",
    InputItem: "请输入{name}",
    PleaseSelect: "请选择{name}",
    SureToPerform: "您确定要对已选中设备执行{action}操作吗？",
    PleaseUploadFormat: "请上传 {name} 格式文件",
    PleaseUpload: "请上传应用安装包",
    selectFileAgain: "你选择的文件是非法文件，请重新选择。",
    ThePictureSize: "图片大小不能超过 {size}",
    EnterScreenPassword: "请输入6位数字密码",
    KeyFormat: "KEY格式为8位数字",
    UploadPictureFormat: "请上传PNG格式，背景透明，而且大小在300KB以内的图片",
    UploadPictureFormat2: "请上传JPG/PNG格式，且大小在300KB以内的图片",
    LicenseIncorrect: "文件格式不正确，文件名称必须为license.lic",
    UpperLimit: '用户量已达上限，请升级license证书',
    SuccessfulOperation: "操作成功",
    SettingOfSensitiveWords: "敏感词设置",
    SensitiveWords: '敏感词',
    FrequencyOfReporting: '上报频率({name})',
    minutes: "分钟",
    SaveDays: '保存天数({name})',
    Days: '天',
    FormatError: "格式错误",
    AssociationApp: '关联的应用',
    PasswordInExit: "允许退出安全桌面",
    DesktopConfigItem: "桌面配置项",
    DesktopAppTips: "在安全桌面内需要显示应用商城外的应用（应用名称和程序ID须保持一致）",
    DisplayCalls: "显示通话",
    DisplayContacts: "显示联系人",
    DisplaySMS: "显示短信",
    ApplicationProgram: '应用程序',
    SettingsOfWhiteList: '白名单设置',
    Address: '地址',
    SettingofAppFence: "应用围栏设置",
    AppRestrictionMode: "应用限制方式",
    AllowEnclosure: "在时间/地理围栏内允许使用",
    EntryNotice: "进入围栏通知",
    OpenBellNotice: "开启铃声通知",
    IssueNotice: "策略下发通知",
    OpenMessageNotice: "开启消息通知",
    Chinese: '中文',
    English: '英文',
    AdministratorsName: '管理员姓名',
    PhoneNumber: '电话号码',
    NewPassword: '新密码',
    OldPassword: '旧密码',
    BlackListName: "黑名单名称",
    whiteListName: "白名单名称",
    ChangeBasicInformation: "修改基本信息",
    ChangePassword: "修改密码",
    // 首页
    TotalNumber: '孩子总数',
    ActiveDevices: '在线孩子',
    TotalAccounts: '用户总数',
    ActiveAccounts: '激活用户',
    SystemStatusMonitoring: '系统状态监控',
    IdleResources: '空闲资源',
    Memory: '内存',
    UsageRate: '使用率',
    DeviceViolationStatus: '设备违规状态',
    NormalDevices: '正常设备',
    IllegalResources: '违规资源',
    Locking: '锁定',
    Eliminate: '淘汰',
    Erasure: '擦除',
    DeviceLastTime: '最后次设备位置',
    ImplementationStatus: '策略执行情况',
    LastCommand: '最后次命令',
    LastOnline: "最近上线",
    ApplicationStatus: "应用安装情况",
    AlreadyIssuedStrategy: '已下发策略',
    AlreadyUsed: '已使用',
    Policy: '策略管理',
    DeviceConfig: '终端配置',
    WIFIConfig: 'WIFI配置',
    VPNConfig: 'VPN配置',
    APNConfig: 'APN配置',
    UpSensitiveSet: "最多可设置100个敏感词",
    ApplicationMall: '应用商城',
    AffectedObject: "影响对象",
    LogAndReport: '日志报表',
    LogUploading: "日志上传",
    AdministratorLog: '管理员日志',
    OperationLog: '操作日志',
    LoginLog: '登录日志',
    OperationType: "操作类型",
    IPAddress: "IP地址",
    UserLog: '用户操作日志',
    UserLoginLog: '孩子登录日志',
    UserLogin: '用户登录',
    SwitchoverReason: "切换原因",
    SwitchoverDirection: "切向方向",
    SwitchingTime: "切换时间",
    SystemSwitchingLog: '系统切换日志',
    System: '系统',
    ManMade: '人工',
    ViolationTime: "违规时间",
    LossOfTime: "失联计时",
    SystemViolationLog: '系统违规日志',
    LostViolationLog: '失联违规日志',
    ApplyViolation: '应用违规日志',
    IllegallyInstalledApplications: "违规安装应用",
    CallReminderLog: '来电提醒日志',
    CallerNumber: "来电号码",
    CallTime: "通话时间",
    LogName: "日志名称",
    UploadTime: "上传时间",
    DebugLog: '调试日志',
    UserPrivacyLog: '用户隐私日志',
    UserActionTrack: '用户行动轨迹',
    CallRecordingLog: '通话录音记录',
    SMSRecord: '短信记录',
    PlatformMessage: '平台消息',
    Read: '已读',
    UnRead: '未读',
    CallNumber: "通话号码",
    Communicator: "通讯人",
    CallType: "通话类型",
    TalkTime: "通话时间",
    CallRecording: "通话录音",
    SendingTime: "发送时间",
    SMSType: "短信类型",
    SMSContent: "短信内容",
    Normal: '正常',
    Expired: "已过期",
    WorkUnit: '工作单位',
    RelatedChild: "关联孩子",
    RelatedChildren: "子女数量",
    RelatedParent: "关联家长",
    StudentID: "学号",
    Parent: "家长",
    ApplicationMonitor: '应用监测',
    DataTraffic: "数据流量",
    PowerConsumption: "耗电量",
    Runtime: "运行时长",
    ApplicationInstallationPolicy: '应用安装策略',
    ApplicationRunningPolicy: '应用运行策略',
    PolicyExecutionTracking: '策略执行跟踪',
    UnLock: '未锁定',
    SystemConfiguration: '系统配置',
    ConfigurationValue: '配置值',
    WhitelistPersonalCalls: '个人通话白名单',
    PhoneShort: '手机短号',
    Remarks: '备注',
    MaxWord: '最多 {name} 字',
    Intersection: "交集",
    Union: "并集",
    Mode: "模式",
    DaysAgo: "{n}天前",
    MinutesAgo: "{n}分钟前",
    HoursAgo: "{n}小时前",
    Hours: '小时',
    Receive: '接收',
    SendOut: '发送',
    CallIng: '呼入',
    CallOut: '呼出',
    UploadOnlyWiFi: "仅在WIFI下上传",
    LogTimeRange: "日志上传的时间范围为选择的截止日期(含)前七天",
    UnRunning: '未运行',
    Running: '正在运行',
    SystemApplication: '系统应用',
    ApplicationofDownload: '下发的应用',
    UserInstallationApplications: '用户安装的应用',
    SecretLockScreenMobilePhone: '手机端的锁屏秘钥',
    EgBaidu: "如：百度",
    HttpStart: "请以http://或https://开头",
    ConnectionName: "连接名称",
    EquipmentOperationLog: '设备操作日志',
    PlatformOperationsLog: '平台操作日志',
    ClientOperationsLog: '客户端操作日志',
    ImportWhitelistPersonalCalls: '请导入个人通话白名单',
    NoResult: '暂无数据',
    PleaseWriteSecretKey: '秘钥有误，请重新输入',
    WiFiHotspot: 'wifi热点',
    ViolationEquipment: '违规设备',
    ViolationDetail: '违规详情',
    ViolationMeasures: '违规措施',
    MapLoadingFailed: '地图加载失败，请开放地图端口',
    PolicyName: '策略名称',
    Detail: '详情',
    IsViolation: '是否违规',
    IsNeedPassword: '是否需要密码',
    IsIssued: "是否下发",
    AutoSystemViolation: "自动下发系统违规",
    AutoLostViolation: "自动下发失联违规",
    DatabaseBackupLog: "数据库备份日志",
    BackupFileName: "备份文件名称",
    BackupDate: "备份日期",
    TelebookPolicy: "通讯录策略",
    AccessToTeleBook: "获取通讯录",
    AccessControlSign: '门禁标识',
    PersonalAddressBook: "个人通讯录",
    AddressBookRecording: "通讯录记录",
    KeepAliveApply: "保活应用",
    KeepAlivePolicy: '应用保活策略',
    Download: '下载',
    SwitchFailure: '切换失败',
    SwitchSuccess: '切换成功',
    SelectUnitNotEqu: '所选单位暂无设备',
    NoEntryAbnormality: "没有进入人员异常",
    ForcedSwitchToSecurityDomain: '强制切换至安全域',
    ForcedSwitchToLifeDomain: '强制切换至生活域',
    IPLimit: "限制IP登录",
    Cancelled: '已注销',
    InitialPassword: "初始密码",
    ResetPassTo: "将{name}的密码重置为000000",
    ResetPassword: "重置密码",
    LearnModal: "家长代管学习模式",
    NoFile: "暂无此文件",
    StudentPhone: "孩子手机号码",
    ParentPhone: "家长手机号码",
    StudentPhoneExists: "孩子手机号码 {name} 已存在",
    StudentPhoneNotExists: "孩子手机号码 {name} 不存在",
    ParentPhoneNotExists: "家长手机号码 {name} 不存在",
    SameApplicationExists: '存在相同的应用程序',
    Hello: '你好',
    UserCenter: '用户中心',
    IssuingUserInfo: '下发用户信息',
    ParentLeisureStrategy: "家长下发休闲策略",
    ParentsLearningStrategy: "家长下发学习策略",
    ExistsWhitelist: '该通话白名单号码已存在',
    StudyMode: "学习模式",
    SoftwareManagement: "软件管理",
    NoScreenshots: "禁止截屏",
    NoScreencap: "禁止录屏", 
    Screencap: "录屏", 
    EducationManagement: "教务管理", 
    TeacherManagement: "教职工管理", 
    IssueNumber: "下发人数", 
    InstallNumber: "安装人数", 
    DownloadNumber: "下载人数", 
    Superior: "上级",
    IdentityParents: "家长身份",
    Grandpa: "爷爷", 
    Grandma: "奶奶", 
    Grandpa2: "外公", 
    Grandma2: "外婆",
    Other: "其他", 
    NameOfParents: "家长姓名", 
    ParentMobileNumber: "家长手机号码", 
    ParentsOccupation: "家长职业", 
    GenderParents: "家长性别",
    InviteStudents: "邀请孩子", 
    ClientReceived: "客户端是否已收到",
    ResendRecord: "重发记录", 
    SystemLogs: "系统日志",
    FileName: "文件名称", 
    ExportStudentTips: "正在导出孩子，请耐心等待！",
    ExistsPhone1: "该手机号码已存在",
    ExistsStudentNum: "该学号已存在",
    StudentImportCount2: "已导入 {num} 个孩子",
    StudentNumExists: "学号 {name} 已存在",
    ParentPhoneExists: "家长手机号码 {name} 已存在",
    ReplaceParents: "更换家长",
    SelectSuperior: "请选择上级",
    LearningMaterials: "学习资料",
    LearningSoftware: "学习软件",
    IssueRecordLog: "下发记录日志",
    StudentVersion: "孩子端版本",
    LearningApplications: "学习类应用",
    GameApplications: "游戏类应用",
    ShoppingApplications: "购物类应用",
    Uninstalled: "已卸载",
    ActivationFailed: "激活失败",
    ParentManage: "家长端管理",
    Feedback: "意见反馈",
    Source: "来源",
    NoStudent: '暂无孩子',
    RegistrationRime: "注册时间",
    ReasonForUnbind: '解绑原因',
    UnbindRecord: '解除绑定记录',
    AlreadyBound: '已绑定',
    OrderManage: '订单管理',
    MemberManage: '会员管理',
    OrderName: '订单名称',
    OrderNum: '订单编号',
    OrderTime: '下单时间',
    PaymentStatus: '支付状态',
    ToBePaid: '待支付',
    PaymentSuccess: '支付成功',
    PaymentFail: '支付失败',
    PaymentTime: '支付时间',
    PaymentMethod: '支付方式',
    UnitPrice: '单价',
    TotalAmount: '总金额',
    MonthsOfPurchase: '购买月数',
    MonthsOfGift: '赠送月数',
    WeChat: '微信',
    Alipay: '支付宝',
    WingPay: '翼支付',
    ApplyType: "应用类型",
    PurchaseTime: "购买时间",
    DueDate: "到期时间",
    Weight: "权重",
    EnterPositiveInteger: "只能输入正整数",
    LearningSection: "学段",

    MallManagement: "商城管理",
    ProductManagement: "商品管理",
    OrderManagement: "订单管理",
    Price: "价格",
    ShelveStatus: "上架状态",
    Shelve: "上架",
    OffShelve: "下架",
    Picture: "图片",
    Parameter: "参数",
    ProductDescription: "商品描述",
    MemberGiftDuration: "会员赠送时长",
    MemberGiftDescription: "会员赠送描述",
    ProductName: "商品名称",
    ProductParameters: "商品参数",
    ImageUploadType: "仅允许上传jpg、jpeg、png、bmp、gif格式图片",
    ImageUploadSize: "图片大小必须在5M以内",
    UserManagement: "用户管理",
    AgentManagement: "代理商管理",
    PromotionRecord: "推广记录",
    Brand: "厂家",
    FinancialStatements: "财务报表",
}
export default zh_CN;