const en_US = {
    Add: "Add",  // 添加
    Update: "Update", // 修改
    Delete: 'Delete', //删除
    Move: "Move", // 移动
    Yes: 'Yes', //是
    No: 'No', //否
    OK: "OK", // 确定
    Cancel: 'Cancel', //取消
    Import: "Import", // 批量导入
    Reimport: "Reimport", // 重新导入
    Export: "Export", // 导出
    Search: "Search", // 搜索
    All: "All", // 全部
    Save: "Save", // 保存
    See: 'See', //查看
    Reset: "Reset", // 重置
    MoreOperations: 'More operations', // 更多操作
    Prompt: 'Prompt', //提示
    Issue: 'Issue', //下发
    IssuingDetails: 'Issuing details', //下发详情
    DownTime: 'Down time', //下发时间
    ApplicationState: "Application state", //应用状态
    DeviceDetails: 'Device details', // 设备详情
    UserDetails: 'User details', // 用户详情
    Size: 'Size', // 大小
    Copy: "Copy", // 复制
    UserInfo: "User Info", // 用户信息
    ParentManagement: "Parent", //家长管理
    DeviceManagement: "Student", //设备管理
    Device: "Device", // 设备
    DeviceLimitation: "Device limitation", // 设备限制
    ApplicationManagement: 'Application', //应用管理
    SecurityPolicy: "Security policy", // 安全策略
    FileManagement: 'File', // 文件管理
    AccountInfo: "Account Info", //账号资料
    Modifypassword: "Modify password", //修改密码
    Logout: "Logout", //退出登录
    FullName: "Full name", // 姓名
    ChineseName: "Chinese name",  //中文名称
    EnglishName: "English name", // 英文名称
    RequestUrl: "Request Url",// 请求地址
    RequestMethod: "Request method", // 请求方法
    Sort: "Sort", // 排序
    IsItMenu: "Is it a menu?", // 是否是菜单
    Settings: 'Settings', //设置
    MenuManagement: "Menu management", // 菜单管理
    RoleManagement: "Role management", // 角色管理
    AccountManagement: "Account management", // 账号管理
    UserLabel: "User label", // 用户标签
    DeviceAccess: "Device access", // 设备准入
    HomePage: "Home Page", //首页
    ParentMenu: "Parent menu", // 父级菜单
    Submenu: "Submenu", // 子级菜单
    Button: "Button", // 按钮
    IconName: "Icon name", // 图标名称
    Icon: 'Icon', // 图标
    toplevel: "Top level", // 顶级
    Name: 'name', //名称
    Description: 'Description', //描述
    CreationTime: "Creation time", // 创建时间
    AssignPermissions: "Assign permissions", // 分配权限
    Account: 'Account', //账号
    Mailbox: "Email", //邮箱
    Role: "Role", // 角色
    MobileNumber: "Mobile number", // 手机号码
    Password: 'Password', //密码
    ConfirmPassword: "Confirm password", //确认密码
    PasswordGenerationMode: "Password generation mode", //密码生成方式
    AutoGenerate: "Auto generate", //自动生成
    ManualInput: 'Manual input', //手动输入
    ActivationDevice: "Activation student", // 激活设备
    ActivationState: 'Activation state', //激活状态
    AlreadyActivated: 'Already activated', //已激活
    NotActive: 'Not active', //未激活
    AddMode: "Add mode", // 添加方式
    PhoneCornet: "Phone cornet", // 手机短号
    DownloadApp: "Download application", // 下载应用
    DownloadTemplate: "Download import template", // 下载导入模板
    DownloadUserTemplate: "Download user template", // 下载用户模板
    DownloadDeviceTemplate: "Download device template", // 下载设备模板
    SendMailActivation: "Send mail activation", // 发送邮件激活
    Configure: "Configure", // 配置
    LimitSIMCard: "Limit the SIM Card IMSI", // 限制SIM卡IMSI
    LimitDevice: "Limit the terminal IMEI", // 限制终端IMEI
    LimitSystem: "Limit the system", // 限制系统
    LimitTFCard: "Limit the TF card serial number", // 限制TF卡
    LimitName: "Limit the name", // 限制姓名
    LimitIDCard: "Limit the ID Card", // 限制身份证
    DeviceAccessInformation: "Device access information", // 设备准入信息
    Creator: "Creator", // 创建者
    AlreadyApplied: 'Already applied', // 已应用
    NotApply: 'Not apply', // 未应用
    HaveReceived: "Have received", // 已收到
    AlreadyIssued: 'Already issued', //已下发
    NotIssued: "Not issued", // 未下发
    AlreadyInstalled: 'Already installed', //已安装
    NotInstalled: 'Not installed', //未安装
    UpdateTime: 'Update time', //更新时间
    BasicInformation: 'Basic information', // 基本信息
    StrongControl: "Strong control", // 强管控
    DeviceLockScreen: "Device lock screen", // 设备锁屏
    ViolationDetection: "Violation detection", // 违规检测
    PrivacyPolicy: "Privacy policy", // 隐私策略
    Camera: 'Camera', // 摄像头
    Entrance: "Entrance", // 入口
    Exit: "Exit", // 出口
    SelectFence: "Select a fence", // 选择围栏
    SelectDate: "Select date", // 选择日期
    TimeFence: 'Time fence', // 时间围栏
    Geofence: 'Geofence', // 地理围栏
    WLANFence: "WLAN fence", // WLAN围栏
    OnlineState: "Online state", // 在线状态
    Online: 'Online', //在线
    Offline: 'Offline', //离线
    DeviceModel: 'Device Model', // 设备型号
    ClientVersion: 'Client Version', // 客户端版本
    State: 'State', //状态
    TableOperation: "Table operation", // 表格操作
    Operation: 'Operation', //操作
    ProhibitionUse: "Prohibition of use", // 禁止使用
    AllowUse: "Allow use", // 允许使用
    ScanAllowedOnly: "Scan allowed only", // 仅允许扫描
    DeviceUpdate: 'Device Update', //设备更新
    PlayRingtone: 'Play Ringtone', //播放铃声
    SendMessage: 'Send Message', //发送消息
    LockDevice: 'Lock Device', // 设备锁定
    UnlockDevice: 'Unlock Device', // 设备解锁
    Open: 'Open', // 开启
    Close: 'Close', // 关闭
    EnableCallWhiteList: "Enable call white list", //开启电话白名单
    DisableCallWhiteList: 'Disable call white list', // 关闭电话白名单
    UpdateTelephoneBook: "Update telephone book", // 更新电话本
    EraseDevice: 'Erase Device', // 设备擦除
    ReleaseControl: 'Release control', // 解除管控
    ObsoleteDevice: 'Obsolete Device', // 设备淘汰
    SetFlow: 'Set Flow', // 设置流量
    DeviceShutdown: 'Device Shutdown', // 设备关机
    DeviceReset: 'Device Reset', // 设备重启
    SwitchToSecurityDomain: 'Switch to security domain', // 切换至安全域
    SwitchToLifeDomain: 'Switch to life domain', // 切换至生活域
    MessageType: "Message type", // 消息类型
    Notice: "Notice", // 通知
    Mail: "Mail", // 邮件
    MessageContents: "Message contents", // 消息内容
    Version: 'Version', //版本
    Platform: 'platform', //平台
    AllPlatforms: "All platforms", // 所有平台
    ApplicationName: 'Application name', //应用名称
    ApplicationSize: 'Application size', //应用大小
    Operator: 'Operator', //操作者
    Operator2: 'Operator', //运营商
    UploadAppPackage: "Upload application package", // 上传应用安装包
    LowestRequirement: 'Lowest OS requirement', //最低操作系统要求
    DescriptionClauses: 'Description and use clauses', //描述及使用条款
    AllowAccess: 'Allow Internet access', //允许上网
    AllowUnloading: 'Allow unloading', //允许卸载
    DeviceStatus: 'Device status', //设备状态
    Application: 'Application', //应用
    Files: 'Files', // 文件
    Strategy: 'Policy', // 策略
    DeviceType: 'Device type', // 设备类型
    DeviceName: 'Student name', // 设备名称
    CurrentOS: 'Current OS', // 当前操作系统
    AppPassword: 'App encryption password', // app屏保密码
    ActivationTime: 'Activation time', // 激活时间
    LatestOnline: 'Latest online', // 最新上线
    LastUpdateTime: 'Last update time', // 上次更新时间
    DeviceManufacturer: 'Device manufacturer', // 设备厂商
    SDCardCapacity: 'SD card capacity', //SD卡容量
    MainCard: 'Main card', // 主卡
    SecondaryCard: 'Secondary card', // 副卡
    Android: 'Android', //安卓
    AlreadyLocked: 'Already locked', // 已锁定
    LostConnection: 'Lost connection', // 已失联
    Irregularities: 'Irregularities', // 已违规
    Eliminated: "Eliminated", // 已淘汰
    Erased: "Erased", // 已擦除
    SDCardNumber: 'SD card sequence number', // SD卡序列号
    SDCardCapacityCanUse: 'SD card available capacity', // SD卡可用容量
    // Camera: 'Camera', // 相机
    MACAddress: 'MAC address', // MAC地址
    Model: 'Model', // 机型
    ModelName: "Model name",  //机型名称
    TotalCapacityOfROM: 'Total capacity of ROM', // ROM总容量
    ROMAvailableCapacity: 'ROM available capacity', // ROM可用容量
    PowerState: 'Power state', //电源状态
    StartingTime: 'Starting time', // 开机时长
    BluetoothMACAddress: 'Bluetooth MAC address', // 蓝牙MAC地址
    DeviceMACAddress: "Device MAC address", // 设备MAC地址
    WhetherOrNotRooted: 'Whether or not Rooted', // 是否Rooted
    PrisonBreak: 'Prison Break', //越狱
    OutContact: 'Out of contact', //失联
    HardwareInformation: 'Hardware information', // 硬件信息
    TrafficInformation: 'Traffic information', // 流量信息
    FlowQuota: 'Flow quota', //流量配额
    MainCardTraffic: 'Main card mobile network traffic', //主卡移动网络流量
    ViceTraffic: 'Vice card mobile network traffic', // 副卡移动网络流量
    Today: 'Today', // 当天
    ThisWeek: 'This week', // 本周
    ThisMonth: 'This month', // 本月
    AppPackageName: 'Application package name', //应用程序包名
    Type: 'Type', //类型
    ViolatingPolicy: 'Violating Policy', // 违反策略
    InsideTheFence: "Inside the fence", // 围栏内
    OutsideTheFence: "Outside the fence", // 围栏外
    MobileData: "Mobile data", // 移动数据
    NoControl: "No Control", // 不管控
    ForcedOpening: "Forced opening", // 强制开启
    ForcedClosure: "Forced closure", // 强制关闭
    RestrictedUse: "Restricted use", // 受限使用
    Bluetooth: "Bluetooth", //  蓝牙
    BluetoothConfig: "Bluetooth configuration", // 蓝牙配置
    TimeRange: "Time range", // 时间范围
    GeographicRange: "Geographic range", // 地理范围
    longitude: 'longitude', // 经度
    latitude: 'latitude', // 纬度
    Range: "Range", // 范围
    Kilometer: "Kilometer", //公里
    Mile: "Mile", //英里
    MapSelectionScope: "Map selection scope", // 地图选择范围
    SecurityConfigItem: "Security configuration item", // 安全配置项
    InfraredControl: "Infrared control", // 红外控制
    Biometrics: "Biometrics", // 生物识别
    LocationServices: "Location Services", // 定位服务
    LocateDevice: "Locate Device", // 设备定位
    USBWorkMode: "USB work mode", // USB工作模式
    ChargeOnly: "Charge only", // 仅充电
    AccessingExtendedStorage: "Accessing extended storage", // 扩展存储访问
    AllowReadAndWrite: "Allow read and write", // 允许读和写
    ProhibitionOfReadAndWrite: "Prohibition of read and write", // 禁止读和写
    OnlyAllowRead: "Only allow read", // 仅允许读
    Microphone: "Microphone", // 麦克风
    Speaker: "Speaker", // 扬声器
    FlashLight: "Flash light", // 闪光灯
    ExtendedPeripherals: "Extended peripherals", // 扩展外设
    Call: "Call", // 通话
    SMS: "SMS", // 短信
    Screenshot: "Screenshot", // 截屏
    NetworkSharing: "Network sharing", // 网络共享
    APNManagement: "APN management", // APN管理
    APN: 'APN',
    TimeSetting: "Time setting", // 时间设置
    RestoreFactorySettings: "Restore factory settings", //恢复出厂设置
    DebuggingMode: "Development and debugging mode", // 开发调试模式
    SystemUpgrade: "System upgrade", // 系统升级
    OnlyWhiteListCalls: "Allow only white list calls", // 仅允许白名单通话
    OnlyWhiteListSMS: "Allow only white list SMS", // 仅允许白名单短信
    APNNotAllow: "It is not allowed to add, delete, modify, view, and select APN.", // 不允许增加、删除、修改、查看以及选择APN
    APNAllow: "Allow addition, deletion, modification, viewing and selection of APN", //允许增加、删除、修改、查看以及选择APN
    AllowOnlyView: "Allow only view", // 仅允许查看
    ForcedUseNetworkTime: "Forced use of network time", // 强制使用网络时间
    IssueApplication: 'Issue application', //下发应用
    UninstallApplication: "Uninstall application", // 卸载应用
    IssueFile: 'Issue File', // 下发文件
    DeleteFiles: "Delete files", // 删除文件
    IssuePolicy: "Issue Policy", // 下发策略
    IssueRestrictionPolicy: "Issue Restriction Policy",  // 下发限制策略
    IssueSensitivePolicy: "Issue Sensitive word policy", // 下发敏感词策略
    IssueAppInstallPolicy: "Issue Application Installation Policy", //下发应用安装策略
    AppInstallPolicy: "App Install Policy", // 下发应用安装策略
    IssueAppRunPolicy: "Issue Application Running Policy", // 下发应用运行策略
    AppRunPolicy: 'App Run Policy', // 下发应用运行策略
    IssueSecureDesktopPolicy: "Issue Secure desktop Policy", // 下发安全桌面策略
    IssueSecureBrowserPolicy: "Issue Secure browser Policy", // 下发安全浏览器策略
    IssueSystemViolation: "Issue System violation", // 下发系统违规
    IssueApplicationViolation: "Issue Application violation", // 下发应用违规
    IssueLossViolation: "Issue Loss of communication violation", // 下发失联违规
    IssueFencePolicy: "Issue Ring fence Policy", // 下发围栏策略
    FencePolicy: "Fence Policy", // 围栏策略
    IssueTrackPolicy: "Issue Track policy", // 下发轨迹策略
    IssueSMSPolicy: "Issue SMS policy", // 下发短信策略
    IssueCallRecordingPolicy: "Issue Call recording policy", // 下发通话录音策略
    IssueAPN: "Issue apn", // 下发APN配置
    IssueVPN: "Issue vpn", // 下发VPN配置
    VPN: 'VPN',
    Wifi: 'Wifi',
    IssueWifi: "Issue wifi", // 下发WIFI配置
    FileSize: "File size", // 文件大小
    ConfigurationName: "Configuration name", // 配置名称
    AutoJoin: "Auto join", // 自动加入
    HiddenNetwork: "Hidden network", // 隐藏网络
    ServiceSetIdentifier: "Service set identifier", // 服务集标识符
    SecurityType: "SecurityType", //安全类型
    Null: "Null", // 无
    WEPPersonalLevel: "WEP personal level", // WEP个人级
    WPALevel: "WPA or WPA2 personal level", //WPA/WPA2个人级
    ConnectionType: "Connection type", //连接类型
    SharedSecretKey: "Shared secret key", //共享秘钥
    UserName: "User name", //用户名
    Encrypt: "Encrypt", //加密
    AgentServer: "Agent server", //代理服务器
    Port: "Port", //端口
    ParentRole: "Parent role", // 父级角色
    RingFencePolicy: "Ring fence Policy", // 围栏策略
    Fencing: "Fencing", // 围栏方式
    TimeUnit: "Time unit", // 时间单元
    StartTime: "Start time", //开始时间
    EndTime: "End time", // 结束时间
    EveryDay: "Every day", // 每天
    weekly: "weekly", // 每周
    WorkingDay: "Working day", // 工作日
    CertainRimes: "Certain times", // 特定时间
    Monday: "Monday", // 星期一
    Tuesday: "Tuesday", // 星期二
    Wednesday: "Wednesday", // 星期三
    Thursday: "Thursday", // 星期四
    Friday: "Friday", // 星期五
    Saturday: "Saturday", // 星期六
    Sunday: "Sunday", // 星期日
    HardwareControl: "Hardware control", // 硬件管控
    AssociatedUser: "Associated user", // 关联用户
    LabelName: "Label name", // 标签名称
    SelectUsers: "Select users", // 选择用户
    PolicyType: "Policy type", // 策略类型
    DevicePolicy: "Device policy", // 设备策略
    RestrictionPolicy: "Restriction Policy", // 限制策略
    SensitiveWordPolicy: "Sensitive word policy", // 敏感词策略
    SensitivePolicy: "Sensitive Policy", // 敏感词策略
    TrackPolicy: "Track policy", // 轨迹策略
    CallRecordingPolicy: "Call recording policy", // 通话录音策略
    SMSPolicy: "SMS policy", // 短信策略
    ViolationPolicy: "Violation Policy", // 违规策略
    SystemViolation: "System violation", // 系统违规
    ApplicationViolation: "Application violation", // 应用违规
    LossViolation: "Loss of communication violation", // 失联违规
    AppPolicy: "Application Policy", // 应用策略
    ApplyControlPolicy: "Application of Control Strategy", // 应用管控策略
    SecureDesktop: "Secure desktop", // 安全桌面
    SecureDesktopPolicy: "Secure desktop Policy", // 安全桌面策略
    SecureBrowser: "SecureBrowser", // 安全浏览器
    SecureBrowserPolicy: "Secure browser Policy", // 安全浏览器策略
    BlackAndWhiteList: "Black and white list", // 黑白名单
    AppFence: "Application ring fence", // 应用围栏
    FaceZones: "Face Zones", // 人脸区域管理
    AccessControlZone: "Access control zone", // 门禁区域管理
    UserStatus: "User status", // 用户状态
    RegionalState: "Regional state", // 区域状态
    FaceZonePolicy: "Face zone Policy", // 人脸区域策略
    AccessControlPolicy: "Access control policy", // 门禁区域策略
    ZoneConfiguration: 'Zone configuration', //区域配置
    CameraConfiguration: 'Camera configuration', //摄像头配置
    MonitoringLog: 'Monitoring log', // 监控日志
    EnterTheRegion: "Enter the region", // 进入区域
    ControlState: "Control state", // 管控状态
    Enter: 'Enter', // 进入
    OutOf: "Out of", // 退出
    NotEntered: 'Not entered', // 未进入
    Control: 'Control', //已管控
    NotControlled: 'Not controlled', //未管控
    ControlFailure: 'Control failure', //管控失败
    FacePhoto: 'Face photo', // 人脸照片
    AddToLabel: "Add to label", // 添加至标签
    UserAssociationList: "User association list", // 用户关联列表
    RTSPStreaming: "RTSP streaming", //RTSP串流
    CameraID: "Camera ID", // 摄像头ID
    CameraName: "Camera name", // 摄像头名称
    CameraType: "Camera type", // 摄像头类型
    ZoneName: "Zone name", // 区域名称
    CurrentPersonnel: 'Current personnel', //当前人员
    EnterAbnormity: 'Enter abnormal', // 进入异常
    LeaveAbnormity: 'Leave abnormal', // 离开异常
    ProcessingEntryAbnormality: 'Processing entry abnormality', // 处理进入人员异常
    ProcessingOutOfAbnormality: 'Processing out of abnormality', //处理离开人员异常
    Logs: 'Logs', //日志
    SwitchoverStatus: 'Switchover status', //切换状态
    Time: 'Time', // 时间
    Action: 'Action', // 动作
    SDCardChange: 'SD card change', //SD卡变更
    SIMCardChange: 'SIM card change', // SIM卡变更
    ViolationTreatment: "Violation treatment", // 违规处理
    LockScreenPassword: "Lock screen password", // 锁屏密码
    ViolationAlarm: "User violation alarm", // 用户违规告警
    MailNotification: "Mail notification", // 邮件通知
    PushMessage: "Push message", // 推送消息
    AdminAlarm: "Admin alarm", // 管理员告警
    BlackList: "Black list", // 黑名单
    WhiteList: "White list", // 白名单
    lossTime: "Loss of connection time", // 失联时间
    DelayExecution: "Delay execution", // 延时执行
    AlarmSettings: 'Alarm settings', // 告警设置
    ReceivingAlarmMailbox: "Receiving alarm mailbox address", // 接收告警邮箱地址
    AccessKey: "Access Key", // 门禁KEY
    ManagementPlatform: 'Management platform', // 管理平台
    BasicSettings: 'Basic settings', // 基本设置
    InterfaceTimeout: "Interface timeout time", // 界面超时时间
    AnyHours: "{n} hours",  // {n}小时
    AnyMinutes: "{n} minutes", // {n}分钟
    LogSavingDays: "Log saving days", //日志保存天数
    AuthorizationPermission: 'Authorization and permission', // 授权许可
    UploadLicense: "Upload License file", //上传License文件
    LicenseInfo: "License authorization information", // License授权信息
    CustomerName: "Customer name", //客户名称
    Template: "Template", //模块
    LicenseType: "License type", //License类型
    OfficialUse: "Official use", //正式使用
    OnTrial: "Trial use", // 试用
    AvailableDate: "Available date", //可使用日期
    UpdateDate: "Update date", //升级日期
    NumberLicenses: "Number of Licenses", //License数量
    NumberUsedLicenses: "Number of used Licenses", //已使用License数量
    PersonalizedSettings: 'Personalized settings', // 个性化设置
    ChangeLogo: "Change logo and background image", //LOGO和背景图修改
    BackgroundImage: "Background image", //背景图
    ActivateMailTemplate: "Activate mail template settings", //激活邮件模板设置
    MailTheme: "Mail theme", // 邮件主题
    MailContent: "Mail content", // 邮件内容
    ClientManagement: 'Client management', // 客户端管理
    VersionUpdate: 'Version update', // 版本更新
    AboutPlatform: 'About the platform', // 关于平台
    HelpInformation: "Help information", //帮助信息
    SupportingContents: "Supporting contents", //支持内容
    LicenseAgreement: "License agreement", //协议许可
    UploadNewVersion: "Upload {name} of a new version", //上传新版本{name}
    operatingSystem: "operating system", // 操作系统
    LatestVersion: "Latest version", // 最新版本
    Upload: "Upload",  //上传
    ReleasedVersion: "Released version", //发布版本
    CustomerCerviceNumber: "Customer service number", //客服电话
    CopyrightStatement: "Copyright statement", //版权声明
    SystemManagement: "System management", // 操作系统管理
    ModelManagement: "Model management", // 机型管理
    PackageName: "Package name", // 包名称
    SystemType: "System type", // 系统类型
    SingleSystem: "Single system", // 单系统
    DualSystem: "Dual system", // 双系统
    SystemVersion: "System version", // 系统版本
    FitModel: "Fit model", // 适配机型
    OperationRecord: "Operation record", // 操作记录
    SpecificOperation: "Specific operation", //具体操作
    InterInorUn: 'Application Interactive Installation/Unloading', // 应用交互安装/卸载
    SilInorUn: 'Application of silent installation/uninstallation', // 应用静默安装/卸载
    ADBInorUn: 'Installation/unloading by ADB mode', // 应用adb方式安装/卸载
    AuthorizedUse: 'Authorized use', // 授权使用
    OpenUse: 'Open use', // 开放使用
    AppAuthorWhitelist: "Application authorization whitelist", // 应用授权白名单
    ApplicationOperationControl: 'Application operation control', // 应用运行控制
    Forbi: 'Forbi', // 禁止运行
    AlwaysAllowRun: "Always allow to run", // 始终允许运行
    AlwaysProhibit: "Always prohibit operation", // 始终禁止运行
    ForcedOperation: 'Forced operation', // 强制运行
    AppWhitelist: "Application white List", // 应用白名单
    AppBlacklist: "Application black list", // 应用黑名单
    DualDomainSwitch: 'Dual domain switching', // 双域切换
    ApplicationConstraints: "Application constraints", // 应用受限方式
    OnlyRunInFence: "Only allowed to run in the fence", // 仅允许在围栏内运行
    ReturnToLogin: "Return to login", // 返回登录
    TotalRecords: "A total of {totalNum} records, and {selectedNum} record has been selected.", // 共{totalNum}条记录，已选择{selectedNum}条记录
    UserImportCount: "{num1} users have been imported and {num2} more can be imported.", // 已导入 {num1} 个用户，还可导入 {num2} 个用户
    ParentImportCount: "{num1} parents have been imported and {num2} more can be imported.", // 已导入 {num1} 个家长，还可导入 {num2} 个家长
    StudentImportCount: "{num1} students have been imported and {num2} more can be imported.", // 已导入 {num1} 个孩子，还可导入 {num2} 个孩子
    UserImportExcessive: "The number of imported users is excessive, currently only {num} users can be imported, please upgrade the license certificate!", // 导入用户数量超额，当前只能导入 {num} 个用户，请升级license证书！
    DeviceImportCount: "{num} devices have been imported",  // 已导入 {num} 个设备
    GetDeviceDetails: "Get device details", // 获取设备详情
    Reissue: "Reissue", // 重新下发
    DeleteByDevice: "Withdraw", // 按设备删除
    IssueByUser: "Issue by user", // 按用户下发
    IssueByDevice: "Issue by device", // 按设备下发
    DayAccount: "{days} days, {hours} hours, {minutes} minutes", // {days}天{hours}小时{minutes}分钟
    HoursAccount: "{hours} hours, {minutes} minutes", // {hours}小时{minutes}分钟
    MinutesAccount: "{minutes} minutes", // {minutes}分钟
    Unexecuted: "Unexecuted", // 未执行
    Timing: "Timing", // 计时中
    Executed: "Executed", // 已执行
    Relieved: "Relieved", // 已解除
    IDCard: "ID card", // 身份证
    Identity: "Identity", // 身份
    Father: "Father", // 父亲
    Mather: "Mather", // 母亲
    Gender: "Gender", // 性别
    Man: "Man", // 男
    Woman: "Woman", // 女
    RegistrationEquipment: "Registration equipment", // 注册设备
    ActivatingQRCode: "Activating QR Code", // 激活二维码
    DeleteApp: "Delete application", // 删除应用
    HomeAddress: "HomeAddress", // 家庭住址
    Job: "Job", // 职务
    JobManagement: "Job management", // 职务管理
    Occupation: "Occupation", // 职业
    SureDelete: "After deletion, the data will not be resumed. Are you sure you want to perform this operation?", // 删除后数据将不可恢复，您确定要执行此操作？
    InputChineseName: "Please input the Chinese name of the menu.", // 请输入菜单中文名称
    InputEnglishName: "Please input the English name of the menu.", // 请输入菜单英文名称
    PleaseCheck: "Please check a record in the form.", // 请选中表格中的某一条记录
    SelectRoles: "Please select roles.", // 请选择角色
    SelectUnit: "Please choose your work unit.", // 请选择工作单位
    PleaseConfirmPassword: "Please confirm password", //请确认密码
    TwoPasswordDiff: "Two passwords that you enter is inconsistent!", //两次输入的密码不一致
    passwordRule: 'Please set uppercase letters, letters + numbers, at least 8 digits.', // 请设置首字母大写，必须字母+数字，至少8位数
    password6Bit: 'The password must be 6 bit letters or numbers', //密码必须是6位字母或数字
    InputCorrect: "Please enter the correct {name}", //请输入正确的{name}
    ExistsName: "The name already exists.", // 已存在相同名称
    ExistsPolicyName: "The same policy name already exists", // 已存在相同策略名称
    ExistsAccount: "The same account already exists.", // 已存在相同账号
    ExistsCardNo: "The card no already exists.", // 已存在相同身份证号码
    ExistsIMEI: "The imei already exists.", // 已存在相同IMEI
    ExistsPhone: "The mobile phone number has been registered", // 该手机号码已注册
    CannotSuperRole: "Cannot select Super Administrator role", // 不能选择超级管理员角色
    CannotDeleteRole: "Can't delete top-level roles", // 不能删除最高级角色
    UploadTempFormat: "Please upload it in template format!", // 请使用模板格式上传
    InputNumChars: "Please enter {n} characters.", // 请输入{n}位字符
    LineFormatError: "The {n} line format error", // 第 {n} 行格式错误
    UploadFormat: "Please upload according to template format.", // 请按照模板格式上传
    lineEmpty: "The {n} line {name} cannot be empty", // 第 {n} 行 {name} 不能为空
    lineIncorrect: "The {n} line {name} format is incorrect", // 第 {n} 行 {name} 格式不正确
    lineRepeat: "The {n} line and {m} line {name} are repeated", // 第 {n} 行和第 {m} 行 {name} 重复了
    ImportUser: "Please import user information.", // 请导入用户信息
    AccountExists: "Account {name} already exists.", // 账号 {name} 已存在
    AccountNotExist: "Account {name} does not exist", // 账号 {name} 不存在
    DeviceNameExists: "Device name {name} already exists.", // 设备名称 {name} 已存在
    DeviceNameNotExists: "Device name {name} does not exists.", // 设备名称 {name} 不存在
    PhoneExists: "Phone number {name} already exists.", // 手机号码 {name} 已存在
    CardNoExists: "Card no {name} already exists.", // 身份证号码 {name} 已存在
    IMEIExists: "Imei {name} already exists.", // IMEI {name} 已存在
    InputItem: "Please input the {name}.", // 请输入{name}
    PleaseSelect: "Please select {name}.", // 请选择{name}
    SureToPerform: "Are you sure you want to perform {action} on selected devices?", // 您确定要对已选中设备执行{action}操作吗？
    PleaseUploadFormat: "Please upload {name} format file.", // 请上传{name}格式文件
    PleaseUpload: "Please upload application installation package.", // 请上传应用安装包
    selectFileAgain: "The file you selected is an illegal file. Please select the file again.", //你选择的文件是非法文件，请重新选择文件
    ThePictureSize: "The size of the picture can't exceed {size}", //图片大小不能超过{size}
    EnterScreenPassword: "Please enter the 6 digit password.", // 请输入6位数字锁屏密码
    KeyFormat: "The key format is 8 digit.", // KEY格式为8位数字
    UploadPictureFormat: "Please upload a picture in PNG format, with transparent background and with the size smaller than 300KB", //请上传PNG格式，背景透明，而且大小在300KB以内的图片
    UploadPictureFormat2: "Please upload a picture in PNG or JPG format and with the size smaller than 300KB", //请上传JPG/PNG格式，且大小在300KB以内的图片
    LicenseIncorrect: "The file format is incorrect and the name of the file must be license.lic.", // 文件格式不正确，文件名称必须为license.lic
    UpperLimit: 'Number of users reaches the upper limit, please update your license certificate!', //用户量已达上限，请升级license证书
    SuccessfulOperation: "Successful operation", // 操作成功
    SettingOfSensitiveWords: "Setting of sensitive words", // 敏感词设置
    SensitiveWords: 'Sensitive words', // 敏感词
    FrequencyOfReporting: '',  // 上报频率
    minutes: "minutes",  // 分钟
    SaveDays: 'Save days', // 保存天数
    Days: 'days',  // 天
    FormatError: "Format error", // 格式错误
    AssociationApp: "Association application", // 关联的应用
    PasswordInExit: "Allow exit from secure desktop", // 允许退出安全桌面
    DesktopConfigItem: "Desktop config item", //桌面配置项
    DesktopAppTips: "Display applications not in App store on security desktop (App name and package name must be consistent)", //在安全桌面内需要显示应用商城外的应用（应用名称和程序ID须保持一致）
    DisplayCalls: "Display calls", //显示通话
    DisplayContacts: "Display contacts", //显示联系人
    DisplaySMS: "Display SMS", // 显示短信
    ApplicationProgram: 'Application program', // 应用程序
    SettingsOfWhiteList: 'Setting of White list', // 白名单设置
    Address: 'Address', // 地址
    SettingofAppFence: "Setting of Application ring fence", // 应用围栏设置
    AppRestrictionMode: "Application restriction mode", // 应用限制方式
    AllowEnclosure: "Allowed in the time or geographic enclosure", // 在时间/地理围栏内允许使用
    EntryNotice: "Entry into the fence notice", // 进入围栏通知
    OpenBellNotice: "Open the bell notifications", // 开启铃声通知
    IssueNotice: "Issue Policy notice", //策略下发通知
    OpenMessageNotice: "Opening message notifications", // 开启消息通知
    Chinese: 'Chinese', // 中文
    English: 'English', // 英文
    AdministratorsName: "Administrator's name", // 管理员姓名
    PhoneNumber: 'Phone number', // 电话号码
    NewPassword: 'New Password', // 新密码
    OldPassword: 'Old Password', // 旧密码
    BlackListName: "Black list name", // 黑名单名称
    whiteListName: "WhiteListName", // 白名单名称
    ChangeBasicInformation: "Change basic information", // 修改基本信息
    ChangePassword: "Change password", // 修改修改密码
    // 首页
    TotalNumber: 'Total number of students', //设备总数
    ActiveDevices: 'Active students', //在线孩子
    TotalAccounts: 'Total number of accounts', //用户总数
    ActiveAccounts: 'Active accounts', //激活用户
    SystemStatusMonitoring: 'System status monitoring', //系统状态监控
    IdleResources: 'Idle resources', //空闲资源
    Memory: 'Memory', //内存
    UsageRate: 'Usage rate', //使用率
    DeviceViolationStatus: 'Device violation status', //设备违规状态
    NormalDevices: 'Normal devices', //正常设备
    IllegalResources: 'Illegal resources', //违规资源
    Locking: 'Locking', //锁定
    Eliminate: 'Eliminate', //淘汰
    Erasure: 'Erasure', //擦除
    DeviceLastTime: 'Device location at the last time', // 最后次设备位置
    ImplementationStatus: 'Policy implementation status', // 策略执行情况
    LastCommand: 'Last command', //最后次命令
    LastOnline: "Last Online", // 最近上线
    ApplicationStatus: "Application installation status", //应用安装情况
    AlreadyIssuedStrategy: 'Already issued Policy', //已下发策略
    AlreadyUsed: 'Already used', //已使用
    Policy: 'Policy', // 策略管理
    DeviceConfig: 'Device config', // 终端配置
    WIFIConfig: 'WIFI', // WIFI配置
    VPNConfig: 'VPN', // VPN配置
    APNConfig: 'APN', // APN配置
    UpSensitiveSet: "Up to 100 sensitive words can be set", //最多可设置100个敏感词
    ApplicationMall: 'Application mall', // 应用商城
    AffectedObject: "Affected object", //影响对象
    LogAndReport: 'Log & Report', // 日志报表
    LogUploading: "Log uploading", // 日志上传
    AdministratorLog: 'Administrator log', // 管理员日志
    OperationLog: 'Operation log', // 操作日志
    LoginLog: 'Login log', // 登录日志
    OperationType: "Operation type", //  操作类型
    IPAddress: "IP address", //IP地址
    UserLog: 'User log', // 用户操作日志
    UserLoginLog: 'User login log', // 用户登录日志
    UserLogin: 'User login', // 用户登录
    SwitchoverReason: "Switchover reason", //切换原因
    SwitchoverDirection: "Switchover direction", //切向方向
    SwitchingTime: "SwitchingTime", // 切换时间
    SystemSwitchingLog: 'System switching log', // 系统切换日志
    System: 'System', // 系统
    ManMade: 'Man made', // 人工
    ViolationTime: "Violation time", // 违规时间
    LossOfTime: "Loss of time", // 失联计时
    SystemViolationLog: 'System violation log', // 系统违规日志
    LostViolationLog: 'Lost violation log', // 失联违规日志
    ApplyViolation: 'Apply violation', // 应用违规日志
    IllegallyInstalledApplications: "Illegally installed applications", //违规安装应用
    CallReminderLog: 'Call reminder log', // 来电提醒日志
    CallerNumber: "Caller number", //来电号码
    CallTime: "Call time", // 通话时间
    LogName: "Log name", // 日志名称
    UploadTime: "Upload time", // 上传时间
    DebugLog: 'Debug log', // 调试日志
    UserPrivacyLog: 'User privacy log', // 用户隐私日志
    UserActionTrack: 'User action track', // 用户行动轨迹
    CallRecordingLog: 'Call recording log', // 通话录音记录
    SMSRecord: 'SMS record', // 短信记录
    PlatformMessage: 'Platform message', // 平台消息
    Read: 'Read', // 已读
    UnRead: 'Unread', // 未读
    CallNumber: "Call number", // 通话号码
    Communicator: "Communicator", // 通讯人
    CallType: "Call type", // 通话类型
    TalkTime: "Talk time", // 通话时间
    CallRecording: "Call recording", // 通话录音
    SendingTime: "Sending time", // 发送时间
    SMSType: "Text message type", // 短信类型
    SMSContent: "Text message content", // 短信内容
    Normal: 'Normal', // 正常
    Expired: "Expired", // 已过期
    WorkUnit: 'Work unit', // 工作单位
    RelatedChild: "Related children", // 关联孩子
    RelatedChildren: "Related children", // 子女数量
    RelatedParent: "Related Parent", // 关联家长
    StudentID: "Student ID", // 学号
    Parent: "Parent", // 家长
    ApplicationMonitor: "Application monitoring", // 应用检测
    DataTraffic: "Data traffic", // 数据流量
    PowerConsumption: "Power consumption", // 耗电量
    Runtime: "Runtime", // 运行时长
    ApplicationInstallationPolicy: 'Application Installation Policy', // 应用安装策略
    ApplicationRunningPolicy: 'Application Running Policy', // 应用运行策略
    PolicyExecutionTracking: 'Policy Execution Tracking', // 策略执行跟踪
    UnLock: 'Unlock', // 未锁定
    SystemConfiguration: 'SystemConfiguration', // 系统配置
    ConfigurationValue: 'Configuration Value', // 配置值
    WhitelistPersonalCalls: 'Whitelist of Personal Calls', // 个人通话白名单
    PhoneShort: 'Phone short', // 手机短号
    Remarks: 'Remarks', //备注
    MaxWord: " Max {name} word",  // 最多 {name} 字
    Intersection: "Intersection", // 交集
    Union: "Union", // 并集
    Mode: "Mode", // 模式
    DaysAgo: "{n} days ago", // {n}天前
    MinutesAgo: "{n} minutes ago", // {n}分钟前
    HoursAgo: "{n} hours ago", // {n}小时前
    Hours: 'Hours', // 小时
    Receive: 'Receive', // 接收
    SendOut: 'Send out', // 发送
    CallIng: 'CallIng', // 呼入
    CallOut: 'Call out', // 呼出
    UploadOnlyWiFi: "Upload only on WiFi", // 仅在WIFI下上传
    LogTimeRange: "Log upload time ranges from the selected deadline (inclusive) to the first seven days.", //日志上传的时间范围为选择的截止日期(含)前七天
    UnRunning: 'Unrunning', // 未运行
    Running: 'Running', // 正在运行
    SystemApplication: 'System Application', // 系统应用
    ApplicationofDownload: 'Application of Download', // 下发的应用
    UserInstallationApplications: 'User Installation Applications', // 用户安装的应用
    SecretLockScreenMobilePhone: 'Secret Key of Lock Screen on Mobile Phone', // 手机端的锁屏秘钥
    EgBaidu: "Example: Baidu", // 如：百度
    HttpStart: "Please start with http:// or https://", // 请以http://或https://开头
    ConnectionName: "Connection name", // 连接名称
    EquipmentOperationLog: 'Equipment Operation Log', // 设备操作日志
    PlatformOperationsLog: 'Platform Operations Log', // 平台操作日志
    ClientOperationsLog: 'Client Operations Log', // 客户端操作日志
    ImportWhitelistPersonalCalls: 'Import White list Personal Calls', // 请导入个人通话白名单
    NoResult: 'no result', // 暂无数据
    PleaseWriteSecretKey: 'The secret key is incorrect. Please re-enter it', // 秘钥有误，请重新输入
    WiFiHotspot: 'WiFi Hotspot', // wifi热点
    ViolationEquipment: 'Violation equipment', // 违规设备
    ViolationDetail: 'Violation detail', // 违规详情
    ViolationMeasures: 'Violation measures', // 违规措施
    MapLoadingFailed: 'Map loading failed. Open Map Port', // 地图加载失败，请开放地图端口！
    PolicyName: 'PolicyName', // 策略名称
    Detail: 'Detail', // 详情
    IsViolation: 'Is violation', // 是否违规
    IsNeedPassword: 'Is need password', // 是否需要密码
    IsIssued: "is issued", // 是否下发
    AutoSystemViolation: "Automatic issued System violation", // 自动下发系统违规
    AutoLostViolation: "Automatic issued Lost violation", // 自动下发失联违规
    DatabaseBackupLog: "Database backup log", // 数据库备份日志
    BackupFileName: "Backup file name", // 备份文件名称
    BackupDate: "Backup date", // 备份日期
    TelebookPolicy: "Telebook Policy", // 通讯录策略
    AccessToTeleBook: "Access to TeleBook", // 获取通讯录
    AccessControlSign: 'Access control sign', // 门禁标识
    PersonalAddressBook: "Personal Address Book", // 个人通讯录
    AddressBookRecording: "Address Book Recording", // 通讯录记录
    KeepAliveApply: "Keep Alive Application", // 保活应用
    KeepAlivePolicy: 'Application Keep Alive Policy', // 应用保活策略
    Download: 'Download', // 下载
    SwitchFailure: 'Switch failure', //切换失败
    SwitchSuccess: 'Switch success', // 切换成功
    SelectUnitNotEqu: 'Select unit is not equipment', // 所选单位暂无设备
    NoEntryAbnormality: "No entry abnormality", // 没有进入人员异常
    ForcedSwitchToSecurityDomain: 'Forced switch to security domain', // 强制切换至安全域
    ForcedSwitchToLifeDomain: 'Forced switch to life domain', // 强制切换至生活域
    IPLimit: "IP Login limit", // 限制IP登录
    Cancelled: 'Cancelled', // 已注销
    InitialPassword: "Initial password", // 初始密码
    ResetPassTo: "Reset {name}'s password to 000000", // 将{name}的密码重置为000000
    ResetPassword: "Reset password", // 重置密码
    LearnModal: "Parent-managed Learning Model", // 家长代管学习模式
    NoFile: "File does not exist", //暂无此文件
    StudentPhone: "Student's Mobile Number", //孩子手机号码
    ParentPhone: "Parent's Mobile Number", //家长手机号码
    StudentPhoneExists: "Student's Mobile Number {name} already exists.", //孩子手机号码 {name} 已存在
    StudentPhoneNotExists: "Student's Mobile Number {name} do not exists.", //孩子手机号码 {name} 不存在
    ParentPhoneNotExists: "Parent's Mobile Number {name} do not exists.", // 手机号码 {name} 不存在
    SameApplicationExists: 'The same application exists', // 存在相同的应用程序
    Hello: 'Hello', //你好
    UserCenter: 'User center', //用户中心
    IssuingUserInfo: 'Issuing User Information', //下发用户信息
    ParentLeisureStrategy: "Parents'Leisure Strategy", //家长下发休闲策略
    ParentsLearningStrategy: "Parents'Learning Strategies",  //家长下发学习策略
    ExistsWhitelist: 'The whitelist number already exists', //该通话白名单号码已存在
    StudyMode: "Study Mode", // 学习模式
    SetClassTme: "Set class time", // 设置上课时间
    SoftwareManagement: "Software management", // 软件管理
    NoScreenshots: "No screenshots", // 禁止截屏
    NoScreencap: "No screencap", // 禁止录屏
    Screencap: "Screencap",  // 录屏
    EducationManagement: "Education Management", // 教务管理
    TeacherManagement: "Teacher management", // 教职工管理
    IssueNumber: "Issue number", // 下发人数
    InstallNumber: "Installation number", // 安装人数
    DownloadNumber: "Download number", // 下载人数
    Superior: "Superior", // 上级
    IdentityParents: "Identity of parents", // 家长的身份
    Grandpa: "Grandpa", // 爷爷
    Grandma: "Grandma", // 奶奶
    Grandpa2: "Grandpa", // 外公
    Grandma2: "Grandma", // 外婆
    Other: "Other", // 其他
    NameOfParents: "Name of parents", // 家长姓名
    ParentMobileNumber: "Parent's mobile number", // 家长手机号码
    ParentsOccupation: "Parents' occupation", // 家长职业
    GenderParents: "Gender of parents", // 家长性别
    InviteStudents: "Invite students", // 邀请孩子
    ClientReceived: "client received", // 客户端是否已收到
    ResendRecord: "Resend record", // 重发记录
    SystemLogs: "System Logs", // 系统日志
    FileName: "File Name",  // 文件名称
    ExportStudentTips: "The student is being exported, please wait patiently!", //正在导出孩子，请耐心等待！
    ExistsPhone1: "The phone number already exists", //该手机号码已存在
    ExistsStudentNum: "The student's number already exists", //该学号已存在
    StudentImportCount2: "{num} students have been imported.", //已导入 {num} 个孩子
    StudentNumExists: "Student's numder {name} already exists.", //学号 {name} 已存在
    ParentPhoneExists: "Parent's mobile number {name} already exists.", //家长手机号码 {name} 已存在
    ReplaceParents: "Replace parents", //更换家长
    SelectSuperior: "Please select the superior.", // 请选择上级
    LearningMaterials: "Learning materials", //学习资料
    LearningSoftware: "Learning software", //学习软件
    IssueRecordLog: "Issue record log", //下发记录日志
    StudentVersion: "Student version", // 孩子端版本
    LearningApplications: "Learning applications",  //学习类应用
    GameApplications: "Game applications",  //游戏类应用
    ShoppingApplications: "Shopping applications",  //购物类应用
    Uninstalled: "Uninstalled",  //已卸载
    ActivationFailed: "Activation failed", // 激活失败
    ParentManage: "Parent management", //家长端管理
    Feedback: "Feedback", //意见反馈
    Source: "Source", //来源
    NoStudent: 'No student', //暂无孩子
    RegistrationRime: "Registration time", // 注册时间
    ReasonForUnbind: 'Reason for unbinding', //解绑原因
    UnbindRecord: 'Unbind record', //解除绑定记录
    AlreadyBound: 'Already bound', //已绑定
    OrderManage: 'Order management', //订单管理
    MemberManage: 'Member management', //会员管理
    OrderName: 'Order name', //订单名称
    OrderNum: 'Order number', //订单编号
    OrderTime: 'Order time', //下单时间
    PaymentStatus: 'Payment status', //支付状态
    ToBePaid: 'To be paid', //待支付
    PaymentSuccess: 'Payment successful', //支付成功
    PaymentFail: 'Payment failed', //支付失败
    PaymentTime: 'Payment time', //支付时间
    PaymentMethod: 'Payment method', //支付方式
    UnitPrice: 'Unit Price', //单价
    TotalAmount: 'Total amount', //总金额
    MonthsOfPurchase: 'Months of purchase', //购买月数
    MonthsOfGift: 'Months of gift', //赠送月数
    WeChat: 'WeChat', //微信
    Alipay: 'Alipay', //支付宝
    WingPay: 'Wing pay', //翼支付
    ApplyType: "Application type", // 应用类型
    PurchaseTime: "Purchase time", //购买时间
    DueDate: "Due date", //到期时间
    Weight: "Weight", //权重
    EnterPositiveInteger: "Only positive integers can be entered", //只能输入正整数
    LearningSection: "Learning section", //学段

    MallManagement: "Mall Management", // 商城管理
    ProductManagement: "Product Management", // 商品管理
    OrderManagement: "Order management", // 订单管理
    Price: "Price", // 价格
    ShelveStatus: "Listing status", // 上架状态
    Shelve: "shelve", // 上架
    OffShelve: "off-shelve", // 下架
    Picture: "Picture", // 图片
    Parameter: "Parameter", // 参数
    ProductDescription: "Product Description", // 商品描述
    MemberGiftDuration: "Membership gift duration", // 会员赠送时长
    MemberGiftDescription: "Member Gift Description", // 会员赠送描述
    ProductName: "Product name", // 商品名称
    ProductParameters: "Product parameters", // 商品参数
    UserManagement: "User management", // 用户管理
    AgentManagement: "Agent Management", // 代理商管理
    PromotionRecord: "Promotion record", // 推广记录
    Brand: "Brand", // 厂家
    FinancialStatements: "Financial statements", // 财务报表
}
export default en_US;